import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHeader } from "../../app/store/settingSlice";

// SET TOPNAV TITLE
export const ChangeHeader = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeader(props));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
};
