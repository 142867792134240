import vi from "date-fns/locale/vi";
import React from "react";
import DPK, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import "./index.scss";
registerLocale("vi", vi);

function DatePicker(props) {
  const { t } = useTranslation("toast");
  const language = t("toast:language");
  return (
    <DPK
      dateFormat="dd/MM/yyyy"
      placeholderText="dd/mm/yyyy"
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      locale={language === "vi" ? "vi" : "en"}
      {...props}
    />
  );
}

export default DatePicker;
