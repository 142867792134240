import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./index.scss";
import agent from "../../../app/api/agent";
import { useSelector } from "react-redux";
import { pathBaseRole } from "../../../app/utils/constant";

function ConnectionBox({ id, phone_number, t }) {
  const history = useHistory();
  const {role} = useSelector(({user}) => user.user) || {};

  async function handleChat() {
    if (!id) {
      toast.error(t("toast:er_13"), { autoClose: false });
      return;
    }

    agent.Chat.getChatToken(id)
      .then((res) => {
        const { room_name } = res.data.chat_message;
        history.push(`/${pathBaseRole[role]}/messages/${room_name}`);
      })
      .catch(() => toast.error(t("toast:er_14"), { autoClose: false }));
  }
  return (
    <div className="connection-box flex-box">
      <a
        data-toggle="tooltip"
        data-placement="top"
        title={phone_number || "0858836632"}
        className="call-icon center-box rounded-circle text-light mr-12px"
        href={`tel:${phone_number || "0858836632"}`}
        id="call-icon"
      >
        <FontAwesomeIcon icon={["fas", "phone"]} />
      </a>
      <div
        onClick={handleChat}
        className="chat-icon center-box rounded-circle text-light"
      >
        <FontAwesomeIcon icon={["fas", "comment-lines"]} />
      </div>
    </div>
  );
}

ConnectionBox.propTypes = {
  id: PropTypes.number,
  phone_number: PropTypes.string,
  t: PropTypes.func,
};

export default ConnectionBox;
