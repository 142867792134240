import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";
import useWindowSize from "../../customHooks/useWindowSize";
import AkadonProcess from "../AkadonProcess/AkadonProcess";
import "./index.scss";

const ProcessBox = () => {
  const [width] = useWindowSize();
  const [collapse, setCollapse] = useState(false);

  const { t } = useTranslation(["landing-page", "akadon-process"]);

  return (
    <div className="border-radius-2 mb-3">
      {width > 768 ? (
        <>
          <div className="text-dark text-bold2 ">
            <h5 className="text-dark text-bold2 mb-12px">
              {t("akadon-process:tutor-process")}
            </h5>
          </div>
          <AkadonProcess t={t} />
        </>
      ) : (
        <>
          <div
            onClick={() => setCollapse(!collapse)}
            className="flex-box text-dark text-bold2"
          >
            <h5 className="text-dark text-bold2 mb-12px">
              {t("akadon-process:tutor-process")}
            </h5>
            <FontAwesomeIcon
              icon={["fal", `${collapse ? "angle-up" : "angle-down"}`]}
            />
          </div>
          <Collapse isOpen={collapse}>
            <AkadonProcess t={t} />
          </Collapse>
        </>
      )}
    </div>
  );
};

export default ProcessBox;
