import { configureStore } from "@reduxjs/toolkit";
import { contractsSlice } from "./contractSlice";
import { courseSlice } from "./courseSlice";
import { userSlice } from "./userSlice";
import { videoSlice } from "./videoSlice";
import { teachRequestSlice } from "./tutor/teachRequestSlice";
import { chatSlice } from "./chatSlice";
import { notifySlice } from "./notifySlice";
import { settingSlice } from "./settingSlice";
import { groupSlice } from "./groupSlice";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    video: videoSlice.reducer,
    contract: contractsSlice.reducer,
    course: courseSlice.reducer,
    teachRequest: teachRequestSlice.reducer,
    chat: chatSlice.reducer,
    notify: notifySlice.reducer,
    setting: settingSlice.reducer,
    group: groupSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
