import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TwilioChat from "twilio-chat";
import agent from "../api/agent";

const initialState = {
  roomList: null,
  chatClient: null,
  status: "idle",
};

async function getNewestMessage(chatClient, roomName) {
  try {
    await chatClient?.getSubscribedChannels();
  const channel = await chatClient?.getChannelByUniqueName(roomName);
  if (channel?.channelState.status !== "joined") {
    await channel?.join();
  }
  const messages = await channel?.getMessages(1);
  const lastMessage = Array.from(messages.items)[0];
  return lastMessage
    ? {
        body: lastMessage.state.body,
        createdAt: lastMessage.state.timestamp,
      }
    : null;
  } catch(error) {
    console.log(error);
    return null;
  }
  
}

export const fetchChatHistoryAsync = createAsyncThunk(
  "chat/fetchChatHistoryAsync",
  async (_, thunkApi) => {
    try {
      const { token, room_names } =
        (await agent.Chat.history().then((res) => res.data)) || {};

      const chatClient = await TwilioChat.create(token);

      const roomList = await Promise.all(
        room_names.map(async(room) => {
          const message = await getNewestMessage(chatClient, room.room_name)
          return {...room, message}
        })
      );

      return { chatClient, roomList };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChatHistoryAsync.pending, (state) => {
      state.status = "loadingChatHistory";
    });
    builder.addCase(fetchChatHistoryAsync.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.chatClient = action.payload.chatClient;
      state.roomList = action.payload.roomList;
      state.status = "idle";
    });
    builder.addCase(fetchChatHistoryAsync.rejected, (state) => {
      state.status = "idle";
    });
  },
});
