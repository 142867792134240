import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Label, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import AvatarEditor from "react-avatar-editor";

import "./index.scss";
import Avatar from "../Avatar";
import { updateUserInfo } from "../../../app/store/userSlice";
import agent from "../../../app/api/agent";

const AvatarBox = () => {
  const [isUploadAvatar, setIsUploadAvatar] = useState(false);
  const [localfile, setLocalfile] = useState({ url: "", name: "", type: "" });
  const [loading, setLoading] = useState(false);

  const editorRef = useRef();
  const { avatar, name } = useSelector(({ user }) => user.user) || {};
  const dispatch = useDispatch();
  const { t } = useTranslation(["toast", "profile-tutor", "date", "setting"]);

  // FUNCTION DECLARATIONS
  async function uploadAvatar() {
    // 1. GET IMAGE AS BASE64 FROM CANVAS
    // 2. CONVERT BASE64 => BLOB => FILE OBJECT
    // 3. POST FILE VIA FORMDATA
    const editedImage = editorRef.current.getImageScaledToCanvas().toDataURL();
    const editedFile = await fetch(editedImage)
      .then((res) => res.blob())
      .then(
        (blob) => new File([blob], localfile.name, { type: localfile.type })
      );

    let formData = new FormData();
    formData.append("avatar", editedFile);

    setLoading(true);
    agent.Profile.updateAvatar(formData)
      .then((res) => {
        toast.success(t("toast:sucess_3"));
        const avatar = res.data.avatar || "";
        dispatch(updateUserInfo({ avatar }));
      })
      .catch(() => {
        toast.error(t("profile-tutor:pr_8"));
        setIsUploadAvatar(false);
      })
      .finally(() => setLoading(false));
  }

  function uploadLocalFile(file) {
    if (file?.type.split("/")[0] !== "image") {
      toast.error(t("toast:er_22"), {
        autoClose: false,
      });
      return false;
    }
    setIsUploadAvatar(true);
    const { name, type } = file;
    const url = window.URL.createObjectURL(file);
    setLocalfile({ url, name, type });
  }

  if (!isUploadAvatar) {
    return (
      <div className="avatar-box position-relative me-3">
        <div style={{ height: "193px" }} className="center-box">
          <Avatar avatar={avatar} name={name} width={193} />
        </div>
        <div className="camera-box position-absolute h-100 flex-box justify-content-center align-items-end rounded-circle">
          <Label className="text-light mb-0 pb-2" for="avatar">
            <FontAwesomeIcon icon={["fas", "camera"]} />
          </Label>
          <Input
            onChange={(e) => uploadLocalFile(e.target.files[0])}
            type="file"
            id="avatar"
            className="d-none"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="avatar-box position-relative me-3">
      <AvatarEditor
        ref={(editor) => (editorRef.current = editor)}
        image={localfile.url}
        width={193}
        height={193}
        border={10}
        borderRadius={250}
        color={[255, 255, 255, 0.6]}
        scale={1.2}
        rotate={0}
      />
      <div className="center-box">
        <button
          onClick={() => setIsUploadAvatar(false)}
          className="cancel-btn me-2 py-1 px-2"
        >
          {t("date:cancel")}
        </button>
        <button onClick={uploadAvatar} className="main-btn py-1 px-0 px-2">
          {t("setting:save")}
        </button>
      </div>
      {loading && (
        <div className="loading-box center-box position-absolute">
          <Spinner color="light" />
        </div>
      )}
    </div>
  );
};

export default AvatarBox;
