//      AUTHOR LONGHOANG

//       FILE NÀY ĐỂ CHUYỂN NGỮ CHO PHẦN THỨ 2- CN

import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

function TranslateDate({ datas, forName }) {
  const { t } = useTranslation("date");

  function genDate(params) {
    switch (params * 1) {
      case 0: {
        return t("Date_full1");
      }
      case 1: {
        return t("Date_full2");
      }
      case 2: {
        return t("Date_full3");
      }
      case 3: {
        return t("Date_full4");
      }
      case 4: {
        return t("Date_full5");
      }
      case 5: {
        return t("Date_full6");
      }
      case 6: {
        return t("Date_full7");
      }
      default:
        return ;
    }
  }

  return (
    <Label className="m-0 p-0 cursor-pointer w-100" htmlFor={forName}>
      {genDate(datas)}
    </Label>
  );
}
export default TranslateDate;
