import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import agent from "../api/agent";

const initialState = {
  constractsWithStatus1: null,
  constractsWithStatus4: null,
  constractsWithStatus8: null,
  tutorContracts: null,
  tutorContractsLoaded: false,
  status: "idle",
  filterParams: {},
};

export const fetchContractsAsync = createAsyncThunk(
  "contract/fetchContractsAsync",
  async ({ status, pageNo }, thunkApi) => {
    try {
      const data = await agent.Contracts.student
        .getBaseStatus({ status }, pageNo)
        .then((res) => res.data);
      if (pageNo && pageNo > 1) {
        const currentState =
          thunkApi.getState().contract["constractsWithStatus" + status];
        return {
          ...currentState,
          ...data,
          results: [...currentState.results, ...data.results],
        };
      } else {
        return data;
      }
    } catch (error) {
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const fetchTutorContractsAsync = createAsyncThunk(
  "contract/fetchTutorContractsAsync",
  (payload, thunkApi) => {
    try {
      return agent.Contracts.tutor.getOpen(payload).then((res) => res.data);
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const contractsSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setFilterParams: (state, action) => {
      state.filterParams = action.payload;
      state.tutorContractsLoaded = false;
    },
    resetFilterParams: (state) => {
      state.tutorContractsLoaded = false;
      state.filterParams = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContractsAsync.pending, (state, action) => {
      const { status, pageNo } = action.meta.arg || {};
      if (pageNo < 2) {
        state.status = "loadingConstractsWithStatus" + status;
      }
    });

    builder.addCase(fetchContractsAsync.fulfilled, (state, action) => {
      const { status } = action.meta.arg || {};
      state["constractsWithStatus" + status] = action.payload;
      state.status = "idle";
    });

    builder.addCase(fetchTutorContractsAsync.pending, (state) => {
      state.status = "loadingTutorContracts";
    });

    builder.addCase(fetchTutorContractsAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.tutorContractsLoaded = true;
      state.tutorContracts = action.payload;
    });

    builder.addMatcher(
      isAnyOf(fetchTutorContractsAsync.rejected, fetchContractsAsync.rejected),
      (state) => {
        state.status = "idle";
      }
    );
  },
});

export const { setFilterParams, resetFilterParams } = contractsSlice.actions;
