import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import { initFee } from "../../../initData/feeRange";

function InputFee({ inputFee, data, setData, onChangedProp }) {
  const { t } = useTranslation("landing-page");

  return (
    <>
      <Input
        className="border-radius-2"
        id="budget"
        name="fee"
        type="select"
        required
        value={inputFee}
        onChange={
          onChangedProp
            ? onChangedProp
            : (e) =>
                setData({
                  ...data,
                  fee: e.target.value,
                })
        }
      >
        <option value=""> {t("landing-page:btn_req_4")}</option>
        {initFee.map((fee, index) => (
          <option value={fee.value} key={index}>
            {fee.text}
          </option>
        ))}
      </Input>
    </>
  );
}

export default InputFee;
