import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function useFetchObject(api, payload, recall) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["toast"]);

  // SIDE EFFECTS
  useEffect(() => {
    fetchApi();
    return () => {
      setData();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, recall]);

  // FUNCTION DECLARATIONS
  async function fetchApi() {
    setLoading(true);
    const res = await api(payload);
    setLoading(false);

    // Implement response
    if (res.status < 400) {
      setData(res.data);
    } else if (res.response) {
      toast.error(` ${t("toast:er_1")} ${res.response.status} !`);
    }
  }

  return [data, loading];
}

export default useFetchObject;
