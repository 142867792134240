import React from "react";
import { useTranslation } from "react-i18next";

function HandleType({ type=0 }) {
  // HÌNH THỨC KHÓA HỌC BUỔI/ TUẦN/ THÁNG
  const { t } = useTranslation("suggest");
  const truType = type * 1;

  return (
    <span className="ms-1">
      {truType === 0
        ? t("suggest:sug_28")
        : truType === 1
        ? t("suggest:sug_29")
        : t("suggest:sug_30")}
    </span>
  );
}

export default HandleType;
