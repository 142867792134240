import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import agent from "../../../app/api/agent";
import Hat from "../../../assets/icons/public-hat.svg";
import suitcase from "../../../assets/icons/public-suitcase.svg";
import { levelTranslation, subjectTranslation } from "../../../module";
import useFetch from "../../customHooks/useFetch";

function ContentUnctroll({
  updatedAvatar,
  width,
  height,
  subject_level,
  rate,
  t,
  reviews_count,
  chat_unique,
  experiment,
  Trans,
}) {
  const language = useSelector(({ setting }) => setting?.language);

  const [loading, setLoading] = useState([]);
  const data =
    useFetch(agent.Profile.review, setLoading, false, chat_unique, 1, false) ||
    {};
  const { results } = data;

  function renderImg(wid, hei, avata) {
    return (
      <img
        src={avata}
        style={{ border: "1px solid red" }}
        width={wid}
        height={hei}
        className="me-2 rounded-circle"
        alt="user"
      />
    );
  }

  function renderStar(color) {
    return (
      <FontAwesomeIcon
        className="text-light-grey me-1"
        icon={["fas", "star"]}
        style={{ color: color }}
      />
    );
  }

  function rendenBoxComment(results) {
    function renderComment(value, icon, index) {
      const { star, user_review_name } = value;
      return (
        <div className="mb-3 text-truncate" key={index}>
          {renderImg(
            width / 2,
            height / 2,
            `https://ui-avatars.com/api/?name=${user_review_name}`
          )}
          <span className="font-weight-bold">{user_review_name} </span>
          <span className="text-cl-1">
            {t("common:profile_pop_review")}
          </span>{" "}
          {star} {icon("#fbc00c")}
        </div>
      );
    }
    return (
      <>
        {results
          .slice(0, 2)
          .map((comment, index) => renderComment(comment, renderStar, index))}

        {results.length > 2 && (
          <div>
            <Trans
              i18nKey="common:profile_pop_1"
              values={{ res: results.length - 2 }}
              components={{
                d: <div />,
                t: <span className="text-bold2" />,
              }}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="d-flex flex-row text-dark ">
        <div>{renderImg(width, height, updatedAvatar)}</div>
        <div className="text-truncate ms-2">
          <p className="text-truncate">
            <img alt="suitcase" src={suitcase} width={14} className="me-1" />
            {subject_level?.map((subject, index) => (
              <span key={index}>
                {subjectTranslation(language, subject.name)} -{" "}
                {levelTranslation(language, subject.level)}
                {subject_level.length >= 1 && ", "}
              </span>
            ))}
          </p>
          <p>
            <img alt="suitcase" src={Hat} width={14} className="me-1" />
            {experiment} {t("common:propfile_pop_2")}
          </p>
          <div className="d-flex flex-row">
            <p className="me-2 content-after-line">
              {renderStar("#8F8F8F")}
              {rate} {t("common:profile_pop_rating")}
            </p>
            <p className="ms-2 text-capitalize">
              {reviews_count} {t("common:profile_pop_review")}
            </p>
          </div>
          {loading && loading.length > 0 && <Spinner />}
          {results && rendenBoxComment(results)}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3 ">
        <div className="main-btn-new save-btn me-2 disable-overlay boder-rd-100">
          {t("common:profile_pop_save")}
          <FontAwesomeIcon
            icon={["fas", "arrow-right"]}
            className="btn-arrow"
          />
        </div>
        <Link
          className="main-btn-new ms-2 flex-box justify-content-center"
          to={`/tutordetail/${chat_unique}`}
          target="_blank"
        >
          {t("common:profile_pop_detail")}
          <FontAwesomeIcon
            icon={["fas", "arrow-right"]}
            className="btn-arrow"
          />
        </Link>
      </div>
    </>
  );
}

export default ContentUnctroll;
