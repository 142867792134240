import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  UncontrolledDropdown,
} from "reactstrap";

import FilterIcon from "../../../assets/icons/filter-icon.svg";
import "./index.scss";

function FilterOfflineFlag({ filterOfflineFlag, setFilterOfflineFlag }) {
  const { t } = useTranslation("filter");
  function handleFilter(status) {
    if (filterOfflineFlag.includes(status)) {
      const updateStatus = filterOfflineFlag.filter((s) => s !== status);
      setFilterOfflineFlag([...updateStatus]);
    } else {
      setFilterOfflineFlag([...filterOfflineFlag, status]);
    }
  }

  return (
    <UncontrolledDropdown className="filter-container">
      <DropdownToggle className="flex-box mx-auto justify-content-between bg-grey border-radius-3 text-grey border-0">
        <span className="me-2">{t("teach_method")}</span>
        <img src={FilterIcon} className="filter-icon" alt="filter" />
      </DropdownToggle>

      <DropdownMenu className="p-0">
        <DropdownItem toggle={false} className="py-0">
          <Label
            className="mb-0 cursor-pointer flex-box pe-3 py-1"
            htmlFor="online"
          >
            <Input
              defaultChecked={filterOfflineFlag.includes(false)}
              onChange={() => handleFilter(false)}
              className="mb-0 me-2"
              id="online"
              type="checkbox"
            />
            {t("online")}
          </Label>
        </DropdownItem>

        <DropdownItem toggle={false} className="py-0">
          <Label
            className="m-0 cursor-pointer flex-grow flex-box pe-3 py-1"
            htmlFor="Offline"
          >
            <Input
              defaultChecked={filterOfflineFlag.includes(true)}
              onChange={() => handleFilter(true)}
              className="mb-0 me-2"
              id="Offline"
              type="checkbox"
            />
            {t("offline")}
          </Label>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

FilterOfflineFlag.propTypes = {
  filterOfflineFlag: PropTypes.array,
  setFilterOfflineFlag: PropTypes.func,
};

export default FilterOfflineFlag;
