import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  header: null,
  language: "vi",
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setHeader: (state, action) => {
      state.header = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setHeader, setLanguage } = settingSlice.actions;
