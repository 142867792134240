import vi from "date-fns/locale/vi";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import "./index.scss";
registerLocale("vi", vi);
function StartAndEndTime({ time, setTime, startCaption, endCaption }) {
  const { t } = useTranslation("toast");
  const language = t("toast:language") === "vi" ? "vi" : "en";
  const { start_time, end_time } = time;
  return (
    <div className="start-and-end-time flex-box border border-radius-2">
      <DatePicker
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        locale={language}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        className="border-0 bg-transparent text-center w-100"
        timeCaption={startCaption}
        selected={start_time}
        onChange={(selectedDate) =>
          setTime({ ...time, start_time: selectedDate })
        }
      />
      <span className="mx-2 text-bold2 text-dark">-</span>
      <DatePicker
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        locale={language}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        className="border-0 bg-transparent text-center w-100"
        timeCaption={endCaption}
        selected={end_time}
        onChange={(selectedDate) =>
          setTime({ ...time, end_time: selectedDate })
        }
      />
    </div>
  );
}

StartAndEndTime.propTypes = {};

export default StartAndEndTime;
