import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { subjectColor } from "../../module";
import SubjectImage from "../utils/SubjectImage";
import SubjectTranslation from "./SubjectTranslation";

const DayGridMonthCalendar = ({ events, studentCalendar }) => {
  const { t } = useTranslation("common");
  const init = moment().format("yyyy-MM");

  // LOCAL STATE
  // currentDate IS GET CURRENT DATE ACTIVE AT SIDE CALENDAR
  // eventsInMonth IS SAVE EVENT IN ACTIVE AT SIDE CALENDAR
  const [currentDate, setCurrentDate] = useState(init);
  const [eventsInMonth, setEventsInMonth] = useState([]);

  // IF THIS ONE TRUE CHECK ALL EVENT IN ACTIVE MONTH OF CALENDAR
  studentCalendar &&
    events.map(
      ({ date, subjects }) =>
        moment(date).format("yyyy-MM") === currentDate &&
        // eslint-disable-next-line array-callback-return
        subjects.map((subject) => {
          const exist = eventsInMonth.find(
            (eve) =>
              eve.course_id === subject.course_id &&
              eve.start_time === subject.start_time &&
              eve.subject_name === subject.subject_name
          );

          if (!exist) {
            setEventsInMonth([...eventsInMonth, subject]);
          }
        })
    );

  const calendarHeader = {
    start: "title",
    center: "",
    end: "prev,next",
  };

  const eventContent = (eventInfo) => {
    const { subjects } = eventInfo.event._def.extendedProps;
    const id = eventInfo.event._def.defId;
    return (
      <>
        <div
          className="flex-box h-100 w-100 justify-content-center align-items-end"
          id={`tollltip-${id}`}
        >
          {subjects.map((s, index) => {
            const bg = subjectColor(s.subject_name);
            return (
              <div
                key={index}
                id={`${s.subject_name}-${index}`}
                className="subject-icon rounded-circle"
                style={{
                  background: bg,
                }}
              ></div>
            );
          })}
        </div>
        <UncontrolledTooltip
          hideArrow
          className="op1"
          placement="top"
          target={`tollltip-${id}`}
          innerClassName="bg-light"
          autohide={false}
        >
          <div className="text-dark card-style box-shadow">
            {subjects.map((s, index) => {
              const bg = subjectColor(s.subject_name);
              const start = s.start_time
                ? s.start_time.split(":").slice(0, 2).join(":")
                : "00:00";
              const end = s.end_time
                ? s.end_time.split(":").slice(0, 2).join(":")
                : "00:00";

              return (
                <div key={index} className="flex-box align-items-start mb-2">
                  <div
                    className="rounded-circle align-self-center subject-icon me-2"
                    style={{
                      background: bg,
                    }}
                  ></div>
                  <SubjectImage
                    subject={s.subject_name}
                    width="24px"
                    height="24px"
                  />
                  <div className="ms-2">
                    <h6 className="mb-1 text-left text-bold1">
                      <SubjectTranslation subject={s.subject_name} />
                    </h6>
                    <div className="text-hightlight1 text-small">
                      {start} - {end}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </UncontrolledTooltip>
      </>
    );
  };

  return (
    <div className="day-grid-month-calendar card-style border-radius-2">
      <div className="day-grid-month-calendar__header flex-box justify-content-between mb-4">
        <div className="flex-box align-items-center">
          <div
            className="center-box rounded-circle me-2 shadow-btn-hover"
            style={{ width: "24px", height: "24px", background: "#E0ECF5" }}
          >
            <FontAwesomeIcon
              className="text-hightlight1"
              icon={["fal", "calendar-alt"]}
            />
          </div>
          <h6 className="text-bold2 mb-0">{t("calendar")}</h6>
        </div>
        <Link
          to="/dashboard/calendar"
          className=" flex-box align-items-center text-decoration-none cursor-pointer"
        >
          <span className="text-bold1 text-grey text-small me-2">
            {t("view-calendar")}
          </span>
          <div
            className="center-box  rounded-circle bg-hightlight-1 me-2 btn"
            style={{ width: "24px", height: "24px" }}
          >
            <FontAwesomeIcon
              className="text-light text-bold2"
              icon={["fal", "arrow-right"]}
            />
          </div>
        </Link>
      </div>
      {/*  ADD dateSet and showNonCurrentDates FOR SHOW NEW UI MOBILE */}
      <FullCalendar
        datesSet={(arg) => {
          if (studentCalendar) {
            setCurrentDate(() => moment(arg.start).format("yyyy-MM"));
            setEventsInMonth([]);
          }
        }}
        headerToolbar={calendarHeader}
        titleFormat={
          studentCalendar
            ? { month: "long" }
            : { year: "numeric", month: "numeric" }
        }
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale={t("common:language")}
        events={events}
        eventContent={eventContent}
        showNonCurrentDates={!studentCalendar}
      />
      {/* STATE TRU SHOW THIS ONE */}
      {studentCalendar && (
        <div className="d-flex flex-wrap flex-grow mt-3 pt-3 border-top eventsBox">
          {eventsInMonth.map((s, index) => {
            const bg = subjectColor(s.subject_name);
            const start = s.start_time
              ? s.start_time.split(":").slice(0, 2).join(":")
              : "00:00";

            return (
              <div
                key={index}
                className="text-white py-2 my-2 border-radius-1 text-center eventsBox__elements me-2"
                style={{
                  background: bg || "#03b0a9",
                }}
              >
                {start} <SubjectTranslation subject={s.subject_name} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DayGridMonthCalendar;
