import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";

const initialState = {
  status: "idle",
  groupWithStatus0: null, // join
  groupWithStatus1: null, // ----
  groupWithStatus2: null, // ----
};

export const fetchgroupAsync = createAsyncThunk(
  "course/fetchgroupAsync",
  async (payload, thunkApi) => {
    try {
      const data = await agent.ClassRoom.listGroup(payload).then(
        (res) => res.data
      );
      if (payload.pageNo && payload.pageNo > 1) {
        const currentState =
          thunkApi.getState().group["groupWithStatus" + payload.status];
        return {
          ...currentState,
          ...data,
          results: [...currentState.results, ...data.results],
        };
      } else {
        return data;
      }
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchgroupAsync.pending, (state, action) => {
      const { status, pageNo } = action.meta.arg || {};
      if (pageNo < 2) {
        state.status = "pendingFetchGroup" + status;
      }
    });

    builder.addCase(fetchgroupAsync.fulfilled, (state, action) => {
      const { status } = action.meta.arg || {};
      state["groupWithStatus" + status] = action.payload;
      state.status = "idle";
    });

    builder.addCase(fetchgroupAsync.rejected, (state) => {
      state.status = "idle";
    });
  },
});
