import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { PAGE_SIZE } from "../utils/constant";

const initialState = {
  notifies: null,
  unReadCount: null,
  status: "idle",
};

export const fetchNotifiesAsync = createAsyncThunk(
  "notify/fetchNotifiesAsync",
  async ({ pageNo }, thunkApi) => {
    try {
      return await agent.Notify.getList(pageNo).then((res) => res.data);
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const fetchUnReadCountAsync = createAsyncThunk(
  "notify/fetchUnReadCountAsync",
  async (_, { noties }, thunkApi) => {
    try {
      const currentPage = Math.ceil(noties / PAGE_SIZE);
      await fetchNotifiesAsync({ pageNo: currentPage });
      return await agent.Notify.unReadCount().then(
        (res) => res.data?.noti_count
      );
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const markReadAsync = createAsyncThunk(
  "notify/markReadAsync",
  async ({ noti_id, notify }, thunkApi) => {
    try {
      await agent.Notify.markRead(noti_id);
      thunkApi.dispatch(fetchUnReadCountAsync({ noties: notify }));
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const markAllReadAsync = createAsyncThunk(
  "notify/markAllReadAsync",
  async ({ notify }, thunkApi) => {
    try {
      await agent.Notify.markAllRead();
      thunkApi.dispatch(fetchUnReadCountAsync({ noties: notify }));
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const notifySlice = createSlice({
  name: "notify",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchNotifiesAsync
    builder.addCase(fetchNotifiesAsync.pending, (state) => {
      state.status = "loadingNotifies";
    });
    builder.addCase(fetchNotifiesAsync.fulfilled, (state, action) => {
      const currentNotifies = state.notifies;
      if (currentNotifies) {
        state.notifies = {
          ...currentNotifies,
          ...action.payload,
          results: [...currentNotifies.results, ...action.payload.results],
        };
      } else {
        state.notifies = action.payload;
      }

      state.status = "idle";
    });
    // fetchUnReadCountAsync
    builder.addCase(fetchUnReadCountAsync.pending, (state) => {
      state.status = "loadingUnReadCount";
    });
    builder.addCase(fetchUnReadCountAsync.fulfilled, (state, action) => {
      state.unReadCount = action.payload;
      state.status = "idle";
    });
    // markReadAsync
    builder.addCase(markReadAsync.fulfilled, (state, action) => {
      const { noti_id } = action.meta.arg;
      const updatedNotifies = state.notifies?.results.map((notify) =>
        notify.id === noti_id ? { ...notify, read_flg: true } : notify
      );
      state.notifies = { ...state.notifies, results: updatedNotifies };
    });
    //
    builder.addCase(markAllReadAsync.pending, (state) => {
      state.status = "loadingMarkAllRead";
    });
    builder.addCase(markAllReadAsync.fulfilled, (state) => {
      const { notifies } = state;
      const updatedNotifies = notifies?.results?.map((notify) => ({
        ...notify,
        read_flg: true,
      }));
      if (notifies) state.notifies = { ...notifies, results: updatedNotifies };
    });
    // matcher
    builder.addMatcher(
      isAnyOf(
        fetchNotifiesAsync.rejected,
        fetchUnReadCountAsync.rejected,
        markAllReadAsync.rejected
      ),
      (state) => {
        state.status = "idle";
      }
    );
  },
});
