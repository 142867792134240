import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import DropdownDateOfWeek from "./DropdownDateOfWeek";
import "./index.scss";

function SuggestDateRange({
  suggestRange,
  setSuggestRange,
  range,
  duration,
  length,
}) {
  // chuyển logic từ để lại bản ghi cuối === lastRange={suggestRange.length === index + 1 ? true : false} sang để lại bản ghi duy nhất (length=suggestRange.length)
  const { t } = useTranslation("date");

  const { start_time } = range;
  let start_date = start_time;
  if (typeof start_time !== "object" && start_time) {
    start_date = moment("2010-01-01 " + start_time).toDate();
  }

  let end_date = start_time && genEndDate(duration, start_date);
  //function auto macke enddate
  function genEndDate(duration, start_date) {
    switch (duration) {
      case "a1": {
        return moment(start_date).add(30, `minute`).toDate();
      }
      case "a2": {
        return moment(start_date)
          .add(30 * 2, `minute`)
          .toDate();
      }
      case "a3": {
        return moment(start_date)
          .add(30 * 3, `minute`)
          .toDate();
      }
      case "a4": {
        return moment(start_date)
          .add(30 * 4, `minute`)
          .toDate();
      }
      case "a5": {
        return moment(start_date)
          .add(30 * 5, `minute`)
          .toDate();
      }
      case "a6": {
        return moment(start_date)
          .add(30 * 6, `minute`)
          .toDate();
      }
      case "a7": {
        return moment(start_date)
          .add(30 * 7, `minute`)
          .toDate();
      }
      case "a8": {
        return moment(start_date)
          .add(30 * 8, `minute`)
          .toDate();
      }

      default:
        return;
    }
  }
  useEffect(() => {
    handleChangeRange({ end_time: end_date || moment().toDate() });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_time, duration]);

  function handleChangeRange(value) {
    const updateRange = suggestRange.map((sg) => {
      if (sg.id === range.id) {
        return { ...range, ...value };
      }

      return { ...sg };
    });
    setSuggestRange([...updateRange]);
  }

  function removeRange() {
    const updatedRange = suggestRange.filter((r) => r.id !== range.id);
    setSuggestRange([...updatedRange]);
  }

  return (
    <div
      className={`${
        length !== 1 && "fix-width-date-picker"
      } suggest-time flex-box mb-12px py-0`}
    >
      <div className="flex-box w-50 border border-radius-2 me-3 px-1 time-pick-box">
        <DatePicker
          selected={start_date}
          onChange={(date) => handleChangeRange({ start_time: date })}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption={t("start_time")}
          dateFormat="HH:mm"
          placeholderText={t("start_time")}
          locale="vi"
          className="border-0 w-100 text-center"
        />
        <span className="mx-2 h2 mb-0">-</span>
        <DatePicker
          disabled
          selected={end_date}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption={t("end_time")}
          dateFormat="HH:mm"
          placeholderText={t("end_time")}
          className="border-0 w-100 text-center bg-light"
          locale="vi"
        />
      </div>
      <div className="flex-box w-50 date-pick-box">
        <div className="flex-box flex-grow border-radius-2 border h-100">
          <span className="mx-2">{t("wait")}</span>
          <DropdownDateOfWeek
            handleChangeRange={handleChangeRange}
            range={range}
          />
        </div>

        {length !== 1 && (
          <div className="icon-remove" onClick={removeRange}>
            <FontAwesomeIcon
              className="text-danger h2 mb-0 ms-2"
              icon={["fas", "times-circle"]}
            />
          </div>
        )}
      </div>
    </div>
  );
}

SuggestDateRange.propTypes = {
  suggestRange: PropTypes.array,
  setSuggestRange: PropTypes.func,
};

export default SuggestDateRange;
