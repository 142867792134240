import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  UncontrolledDropdown,
} from "reactstrap";

import FilterIcon from "../../../assets/icons/filter-icon.svg";
import feeRange from "../../../initData/feeRange";
import "./index.scss";

function FilterFeeV1({ filterFee, setFilterFee, id }) {
  const { t } = useTranslation("filter");

  function handleFilter(fee) {
    const selectedFee = filterFee.find((f) => f.id === fee.id);
    if (selectedFee) {
      const updatedFee = filterFee.filter((f) => f.id !== fee.id);
      setFilterFee([...updatedFee]);
    } else {
      setFilterFee([...filterFee, { ...fee }]);
    }
  }

  return (
    <UncontrolledDropdown className="filter-container">
      <DropdownToggle className="flex-box justify-content-between mx-auto bg-grey border-radius-3 text-grey border-0">
        <span className="me-2">{t("budget")}</span>
        <img src={FilterIcon} className="filter-icon" alt="filter" />
      </DropdownToggle>

      <DropdownMenu className="p-0">
        {feeRange.map((fee) => (
          <DropdownItem key={fee.text} toggle={false} className="py-0">
            <Label
              className="m-0 cursor-pointer flex-box pe-3 py-1"
              htmlFor={`${id}-${fee.id}`}
            >
              <Input
                defaultChecked={filterFee.find((f) => f.id === fee.id)}
                onChange={() => handleFilter(fee)}
                className="mb-0 me-2"
                id={`${id}-${fee.id}`}
                type="checkbox"
              />

              {fee.text}
            </Label>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

FilterFeeV1.propTypes = {
  filterFee: PropTypes.array,
  setFilterFee: PropTypes.func,
};

export default FilterFeeV1;
