import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import ProIcon from "../../../assets/icons/user-pro-icon.svg";
import StandardIcon from "../../../assets/icons/user-standard-icon.svg";
import RatingBox from "../RatingBox";
import ContentUnctroll from "./ContentUnctroll";
import "./index.scss";

const UserBox = ({
  avatar,
  width,
  height,
  name,
  rate,
  priority,
  experiment,
  subject_level,
  chat_unique,
  reviews_count,
  organization,
}) => {
  const { t } = useTranslation(["common"]);

  const baseUrl =
    process.env.REACT_APP_BASE_URL || "https://api.akadon.edu.vn/api/";

  // ADD COLOR BACKGROUND, COLOR AND BOLD TEXT
  const updatedAvatar = avatar
    ? baseUrl + avatar
    : `https://ui-avatars.com/api/?name=${name}&background=0367b4&color=fff&bold=true`;

  function getUrl() {
    switch (priority) {
      case 1:
        return StandardIcon;
      case 2:
        return ProIcon;
      default:
        return organization ? organization?.profile_icon_link : null;
    }
  }

  return (
    <div className="flex-box align-items-center">
      <div id={`tollltip-${chat_unique}`} onClick={(e) => e.stopPropagation()}>
        <img
          src={updatedAvatar}
          width={width}
          height={height}
          className="me-3 rounded-circle cursor-pointer avatar-border-padding"
          alt="user"
        />
      </div>

      <div>
        <div className="flex-box align-items-center">
          <h5
            className="mb-0 text-bold1 text-dark cursor-pointer"
            id={`tollltip-${chat_unique}`}
            onClick={(e) => e.stopPropagation()}
          >
            {name}
          </h5>
          {getUrl() && (
            <img
              className="ms-2"
              src={getUrl()}
              alt="iconPlan"
            />
          )}
        </div>
        <RatingBox rate={rate} />
      </div>

      {/* Handle event hover to avatar and show more Component */}
      {chat_unique && (
        <UncontrolledTooltip
          innerClassName="card-style border-radius-3 p-4"
          popperClassName="navbar-tooltip profile-popup box-shadow border-radius-3"
          hideArrow
          placement="right"
          target={`tollltip-${chat_unique}`}
          autohide={false}
          // trigger="click"
          style={{ fontFamily: "Mulish" }}
        >
          <ContentUnctroll
            Trans={Trans}
            updatedAvatar={updatedAvatar}
            width={width}
            height={height}
            subject_level={subject_level}
            rate={rate}
            t={t}
            reviews_count={reviews_count}
            chat_unique={chat_unique}
            experiment={experiment}
          />
        </UncontrolledTooltip>
      )}
    </div>
  );
};

export default UserBox;
