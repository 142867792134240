
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserHistory } from "history";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/lib/style.css";
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { Router } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import App from "./App";
import { store } from "./app/store/configuringStore.js";
import "./fontawesome.js";
import "./i18n"; // needs to be bundled
import "./main.scss";


export const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.Fragment>
);
