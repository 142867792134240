import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import agent from "../api/agent";

const initialState = {
  user: null,
  settingData: null,
  currentAccount: null,
  accessToken: null,
  isLoading: false,
  status: null, // login success or failed
  isRefreshToken: false,
};

function updateLocalstorageUser(user) {
  const local = JSON.parse(localStorage.getItem("user"));
  return {
    ...local,
    user: { ...local.user, ...user },
  };
}

export const loginAsync = createAsyncThunk(
  "user/loginAsync",
  async (loginCredential, thunkApi) => {
    const { type, ...credential } = loginCredential;
    try {
      let response = null;
      switch (type) {
        case "normal":
          response = await agent.User.login(credential);
          break;
        case "facebook":
          response = credential?.userID
            ? await agent.User.facebookLogin(credential)
            : { errorFace: "lỗi chưa đang ký" };
          break;
        case "zalo":
          response = await agent.User.zaloLogin(credential);
        // eslint-disable-next-line no-fallthrough
        default:
          break;
      }

      if (!response) return null;
      const { access_token, user } = response.data;
      localStorage.setItem("user", JSON.stringify({ access_token, user }));
      return { access_token, user };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const fetchUserAsync = createAsyncThunk(
  "user/fetchUserAsync",
  async (_, thunkApi) => {
    try {
      const user = await agent.User.info().then((res) => res.data);

      localStorage.setItem(
        "user",
        JSON.stringify(updateLocalstorageUser(user))
      );

      return updateLocalstorageUser(user);
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const fetchSettingDataAsync = createAsyncThunk(
  "user/fetchSettingDataAsync",
  (_, thunkApi) => {
    try {
      return agent.Profile.settingData().then((res) => res.data);
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const fetchCurrentAccountAsync = createAsyncThunk(
  "user/fetchCurrentAccountAsync",
  async (_, thunkApi) => {
    try {
      return await agent.User.getLimit().then((res) => res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/editUserAsync",
  async (updateInfo, thunkApi) => {
    try {
      const user = await agent.User.update(updateInfo);

      localStorage.setItem(
        "user",
        JSON.stringify(updateLocalstorageUser(user))
      );

      return updateLocalstorageUser(user);
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const refreshTokenAsync = createAsyncThunk(
  "user/refreshTokenAsync",
  async (token, thunkApi) => {
    try {
      let newToken = null;
      await agent.NoAuther.refreshToken(token).then(
        ({ data }) => (newToken = data)
      );
      return newToken;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
    updateUserInfo: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.access_token;
      state.status = { success: true };
      state.isLoading = false;
    });

    builder.addCase(loginAsync.rejected, (state, action) => {
      state.status = {
        failedWithStatus:
          action.payload?.error?.response?.status ||
          (action.payload?.error?.message && 404),
      };
      state.isLoading = false;
    });

    builder.addCase(refreshTokenAsync.pending, (state) => {
      state.isRefreshToken = true;
    });

    builder.addCase(fetchSettingDataAsync.fulfilled, (state, action) => {
      state.settingData = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchCurrentAccountAsync.fulfilled, (state, action) => {
      state.currentAccount = action.payload;
      state.isLoading = false;
    });
    builder.addCase(refreshTokenAsync.fulfilled, (state, action) => {
      state.isRefreshToken = false;
      if (action.payload?.token) {
        state.accessToken = action.payload.token;
      }
    });
    builder.addCase(refreshTokenAsync.rejected, (state) => {
      state.isRefreshToken = false;
    });

    builder.addMatcher(
      isAnyOf(fetchUserAsync.fulfilled, updateUserAsync.fulfilled),
      (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
      }
    );

    builder.addMatcher(
      isAnyOf(
        loginAsync.pending,
        fetchUserAsync.pending,
        updateUserAsync.pending,
        fetchSettingDataAsync.pending,
        fetchCurrentAccountAsync.pending
      ),
      (state) => {
        state.isLoading = true;
      }
    );

    builder.addMatcher(
      isAnyOf(
        fetchUserAsync.rejected,
        updateUserAsync.rejected,
        fetchSettingDataAsync.rejected,
        fetchCurrentAccountAsync.rejected
      ),
      (state) => {
        state.isLoading = false;
      }
    );
  },
});

export const { setUser, updateUserInfo, logout } = userSlice.actions;
