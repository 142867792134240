export const pathBaseRole = {
  0: "dashboard",
  1: "dashboard-tutor"
}

export const userTypeBaseRole = {
  0: "student",
  1: "tutor"
};

export const PAGE_SIZE = 10;

export const contractType = {
  0: "lesson",
  1: "week",
  2: "month",
  3: "year"
}