import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  UncontrolledDropdown
} from "reactstrap";

import FilterIcon from "../../../assets/icons/filter-icon.svg";
import subjects from "../../../initData/subjectsList";
import SubjectTranslation from "../SubjectTranslation";
import "./index.scss";

function FilterSubjects({ filterSubject, setFilterSubject, id }) {
  const { t } = useTranslation("filter");
  function handleFilter(subject) {
    const isSubjectExist = filterSubject.includes(subject);
    if (isSubjectExist) {
      const updatedSubject = filterSubject.filter((sub) => sub !== subject);
      setFilterSubject([...updatedSubject]);
    } else {
      setFilterSubject([...filterSubject, subject]);
    }
  }

  return (
    <UncontrolledDropdown className="filter-container">
      <DropdownToggle className="flex-box mx-auto justify-content-between bg-grey border-radius-3 text-grey border-0">
        <span className="me-2">{t("subject")}</span>
        <img src={FilterIcon} className="filter-icon" alt="filter" />
      </DropdownToggle>

      <DropdownMenu className="p-0">
        {subjects.map((s) => (
          <DropdownItem key={s} toggle={false} className="py-0">
            <Label
              className="mb-0 cursor-pointer flex-box pe-3 py-1"
              htmlFor={`${id}-${s}`}
            >
              <Input
                defaultChecked={filterSubject.includes(s)}
                onChange={() => handleFilter(s)}
                className="mb-0 me-2"
                id={`${id}-${s}`}
                type="checkbox"
              />
              <SubjectTranslation subject={s} />
            </Label>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

FilterSubjects.propTypes = {
  filterSubject: PropTypes.array,
  setFilterSubject: PropTypes.func,
  id: PropTypes.string,
};

export default FilterSubjects;
