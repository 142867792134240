const feeRange = [
  {
    text: "< 100.000 VNĐ",
    max: 100000,
    min: 0,
    id: 1,
  },
  {
    text: "100.000 - 200.000 VNĐ",
    max: 100000,
    min: 200000,
    id: 2,
  },
  {
    text: "200.000 - 300.000 VNĐ",
    max: 200000,
    min: 300000,
    id: 3,
  },
  {
    text: "300.000 - 400.000 VNĐ",
    max: 300000,
    min: 400000,
    id: 4,
  },
  {
    text: "400.000 - 500.000 VNĐ",
    max: 400000,
    min: 500000,
    id: 5,
  },
  {
    text: "500.000 - 600.000 VNĐ",
    max: 500000,
    min: 600000,
    id: 6,
  },
  {
    text: "600.000 - 700.000 VNĐ",
    max: 600000,
    min: 700000,
    id: 7,
  },
  {
    text: "700.000 - 800.000 VNĐ",
    max: 700000,
    min: 800000,
    id: 8,
  },
  {
    text: "800.000 - 900.000 VNĐ",
    max: 800000,
    min: 900000,
    id: 9,
  },
  {
    text: "> 1.000.000 VNĐ",
    max: null,
    min: 1000000,
    id: 10,
  },
];

export const initFee = [
  { value: 50000, text: "50.000" },
  { value: 100000, text: "100.000" },
  { value: 150000, text: "150.000" },
  { value: 200000, text: "200.000" },
  { value: 250000, text: "250.000" },
  { value: 300000, text: "300.000" },
  { value: 350000, text: "350.000" },
  { value: 400000, text: "400.000" },
  { value: 450000, text: "450.000" },
  { value: 500000, text: "500.000" },
];

export default feeRange;
