import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function CertificateTranslation({ certificate }) {
  const language = useSelector(({ setting }) => setting.language);
  if (language === "vi") return <span>{certificate}</span>;

  switch (certificate) {
    case "Bằng Đại học": {
      return <span>Bằng Đại học</span>;
    }
    case "Bằng Cao đẳng": {
      return <span>Bằng Trung Cấp</span>;
    }
    case "Bằng khác": {
      return <span>Bằng khác</span>;
    }
    case "Chứng chỉ khác": {
      return <span>Chứng chỉ khác</span>;
    }
    case "Học sinh giỏi": {
      return <span>Học sinh giỏi</span>;
    }
    case "Thể thao": {
      return <span>Thể thao</span>;
    }
    case "Nghiên cứu khoa học": {
      return <span>Nghiên cứu khoa học</span>;
    }
    case "Cuộc thi sinh viên": {
      return <span>Cuộc thi sinh viên</span>;
    }
    case "Giải khác": {
      return <span>Giải khác</span>;
    }
    default:
      return <span>{certificate}</span>;
  }
}

CertificateTranslation.propTypes = { certificate: PropTypes.string.isRequired };

export default CertificateTranslation;
