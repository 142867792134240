const subjectList = [
  "Toán học",
  "Ngữ văn",
  "Sinh học",
  "Vật lý",
  "Hóa học",
  "Địa lý",
  "Lịch sử",
  "Tiếng Anh",
  "Tin học",
  "Tiếng Nga",
  "Tiếng Trung",
  "Tiếng Đức",
  "Tiếng Ý",
  "Tiếng Hàn",
];

export default subjectList;
