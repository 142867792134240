import React from 'react';
import English from "../../assets/images/subjects/englishLogo.png";
import Physical from "../../assets/images/subjects/physicsLogo.png";
import Math from "../../assets/images/subjects/mathLogo.png";
import Chemistry from "../../assets/images/subjects/chemistryLogo.png";
import Philology from "../../assets/images/subjects/philology.png";
import History from "../../assets/images/subjects/history.png";
import Biology from "../../assets/images/subjects/biology.png";
import Geography from "../../assets/images/subjects/geography.png";
import Infomatic from "../../assets/images/subjects/infomatic.png";
import Korean from "../../assets/images/subjects/korean.png";
import Chinese from "../../assets/images/subjects/chinese.png";
import Russian from "../../assets/images/subjects/russian.png";
import Germany from "../../assets/images/subjects/germany.png";
import Italian from "../../assets/images/subjects/italian.png";

const SubjectImage = ({subject, width, height, isCircle}) => {
  switch (subject) {
    case "Toán học": {
      return <img className={isCircle && "image-avatar"} src={Math} style={{width, height}} alt="Mathematics" />
    }
    case "Ngữ văn": {
      return <img className={isCircle && "image-avatar"} src={Philology} style={{width, height}} alt="Philology" />
    }
    case "Sinh học": {
      return <img className={isCircle && "image-avatar"} src={Biology} style={{width, height}} alt="Biology" />
    }
    case "Vật lý": {
      return <img className={isCircle && "image-avatar"} src={Physical} style={{width, height}} alt="Physical" />
    }
    case "Hóa học": {
      return <img className={isCircle && "image-avatar"} src={Chemistry} style={{width, height}} alt="Chemistry" />
    }
    case "Địa lý": {
      return <img className={isCircle && "image-avatar"} src={Geography} style={{width, height}} alt="Geography" />
    }
    case "Lịch sử": {
      return <img className={isCircle && "image-avatar"} src={History} style={{width, height}} alt="History" />
    }
    case "Tiếng Anh": {
      return <img className={isCircle && "image-avatar"} src={English} style={{width, height}} alt="English" />
    }
    case "Tin học": {
      return <img className={isCircle && "image-avatar"} src={Infomatic} style={{width, height}} alt="Tin học" />
    }
    case "Tiếng Nga": {
      return <img className={isCircle && "image-avatar"} src={Russian} style={{width, height}} alt="Tiếng Nga" />
    }
    case "Tiếng Trung": {
      return <img className={isCircle && "image-avatar"} src={Chinese} style={{width, height}} alt="Tiếng Trung" />
    }
    case "Tiếng Đức": {
      return <img className={isCircle && "image-avatar"} src={Germany} style={{width, height}} alt="Tiếng Đức" />
    }
    case "Tiếng Hàn": {
      return <img className={isCircle && "image-avatar"} src={Korean} style={{width, height}} alt="Tiếng Hàn" />
    }
    case "Tiếng Ý": {
      return <img className={isCircle && "image-avatar"} src={Italian} style={{width, height}} alt="Tiếng Ý" />
    }
    default: return "";
  }
}

export default SubjectImage;