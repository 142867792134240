import React from "react";
import { Route, Redirect } from "react-router-dom";

const OauthRoute = ({ component, path, ...res }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const pathRole = path?.includes("dashboard-tutor") ? 1 : 0;
 
  if (user && pathRole === user.user?.role) {
    return <Route component={component} path={path} {...res} />;
  } else {
    return <Redirect to="/user/login" />;
  }
};

export default OauthRoute;
