import { vi, enUS } from "date-fns/locale";
import i18next from "i18next";
import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerLocale } from "react-datepicker";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { SubLoader } from "./components/utils";
import OauthRoute from "./OauthRoute";
import Tracking from "./Tracking";
import { setUser } from "./app/store/userSlice";
import { setLanguage } from "./app/store/settingSlice";

const LandingPagetutor = lazy(() =>
  import("./components/LandingPageAkadon/LandingPage/LdTutor/LandingTutor")
);

const LandingPagetutorEu = lazy(() =>
  import("./components/LandingPageAkadonEu/LdTutor/LandingTutor")
);
const LandingPagestudent = lazy(() =>
  import(
    "./components/LandingPageAkadon/LandingPage/LdStudent/LandingStudentNew"
  )
);
// const LandingPagestudentEu = lazy(() =>
//   import("./components/LandingPageAkadonEu/LdStudent/LandingStudent")
// );
const Dashboard = lazy(() => import("./components/dashboardPage/Dashboard"));
const DashboardTutorPage = lazy(() =>
  import("./components/dashboardTutorPage/DashboardTutorPage")
);
const Register = lazy(() => import("./components/Register/Register"));
const ForgetPassword = lazy(() =>
  import("./components/ForgetPassword/ForgetPassword")
);
const NotFound = lazy(() =>
  import("./components/LandingPageAkadon/NotFound/NotFound")
);
const TermsOfService = lazy(() =>
  import("./components/LandingPageAkadon/TermsOfService/TermsOfService")
);
const Login = lazy(() => import("./components/Login/Login"));
const QuandaRegistration = lazy(() =>
  import("./components/QuandaRegistration/QuandaRegistration")
);
const QandaLandingPageV1 = lazy(() =>
  import("./components/QandaLandingPage/QandaLandingPageV1/QandaLandingPage")
);
const QandaLandingPageV2 = lazy(() =>
  import("./components/QandaLandingPage/QandaLandingPageV2/QandaLandingPage")
);
const MarketingPage = lazy(() =>
  import("./components/MarketingPage/MarketingPage")
);
const QRCode = lazy(() => import("./components/OfflinePages/QRCode/QRCode"));
const StartLesson = lazy(() =>
  import("./components/OfflinePages/StartLesson/StartLesson")
);
const LessonFeedback = lazy(() =>
  import("./components/OfflinePages/LessonFeedback/LessonFeedback")
);
const TutorDetail = lazy(() =>
  import("./components/LandingPageAkadon/TutoProfilePublic/TutorDetail")
);
const TutorByPerType = lazy(() =>
  import("./components/LandingPageAkadon/TutorbyPerType/Tutor")
);

const VnpayDemo = lazy(() => import("./components/VnpayDemo"));

const SupportCourse = lazy(() =>
  import("./components/LandingPageAkadon/Support/SupportCourse")
);

function App() {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const { language } = useSelector(({ user }) => user?.settingData) || {};

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    language && dispatch(setLanguage(savedLanguage));
    if (language === "vi") {
      registerLocale("vi", vi);
    } else {
      registerLocale("en", enUS);
    }
    i18next.changeLanguage(language);
  }, [dispatch, language]);

  useEffect(() => {
    setTimeout(() => {
      const subizIcon = document.querySelector("#wpwidget .widget-layout");
      const removeBtn = document.querySelector(
        "#wpwidget .chat-button .remove-subiz-icon"
      );
      if (subizIcon && !removeBtn) {
        const removeBtn = document.createElement("div");
        removeBtn.innerHTML = "&times;";
        removeBtn.classList = "remove-subiz-icon";
        removeBtn.onclick = () => {
          subizIcon.remove();
        };
        subizIcon.prepend(removeBtn);
      }
    }, 2000);

    const savedUser = JSON.parse(localStorage.getItem("user"));
    savedUser &&
      dispatch(
        setUser({ accessToken: savedUser.access_token, user: savedUser.user })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Akadon Education</title>
        <meta
          name="description"
          content="Kênh kết nối dạy và học uy tín, chất lượng cao kết nối giữa gia sư và học viên hàng đầu hiện nay. Cung cấp giải pháp công nghệ dạy trực tuyến đột phá cho việc dạy và học đat chất lượng cao nhất"
        />
      </Helmet>

      <Tracking />
      <ToastContainer position="bottom-center" />

      <Suspense fallback={<SubLoader />}>
        <Switch>
          <Route path="/support" component={SupportCourse} />
          <Route path="/vnpay-demo" component={VnpayDemo} />
          {/* <Route path="/" exact component={LandingPagestudentEu} /> */}
          <Route path="/" exact component={LandingPagestudent} />
          <Route path="/qrcode/:token" component={QRCode} />
          <Route path="/start-lesson/:token" component={StartLesson} />
          <Route path="/feedback-lesson/:token" component={LessonFeedback} />
          <Route path="/giasu" exact component={LandingPagetutor} />
          <Route path="/tutor" exact component={LandingPagetutorEu} />
          <Route path="/qanda" exact component={QandaLandingPageV1} />
          <Route path="/qanda-v2" exact component={QandaLandingPageV2} />
          <Route path="/request-preview" exact component={MarketingPage} />
          <Route path="/qanda/registration" component={QuandaRegistration} />
          <Route
            path="/tutorDetail/:chat_uniqe"
            exact
            component={TutorDetail}
          />
          <Route path="/giasu/loc" exact component={TutorByPerType} />
          <Route path="/terms-of-service" exact component={TermsOfService} />
          <Route path="/user/register" component={Register} />
          <Route path="/user/login" component={Login} />
          <Route path="/user/forget-password" component={ForgetPassword} />
          <OauthRoute path="/dashboard" component={Dashboard} />
          <OauthRoute path="/dashboard-tutor" component={DashboardTutorPage} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
}

export default App;
