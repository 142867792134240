import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faLongArrowDown,
  faArrowToBottom,
  faFilePlus,
  faTimes,
  faTrashAlt,
  faClock,
  faAngleRight,
  faAngleLeft,
  faBookOpen,
  faCalendarAlt,
  faClipboard,
  faNewspaper,
  faSearch,
  faSlidersV,
  faCog,
  faAngleDown,
  faAngleUp,
  faArrowRight,
  faEye,
  faPlus,
  faVideo,
  faVideoSlash,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faExclamationCircle as faExclamationCircleLight,
  faCheck,
  faMoneyCheck,
  faCheckCircle as faCheckCircleLight,
  faLockAlt,
  faUsdCircle,
  faUser,
  faUserPlus,
  faShieldCheck,
  faWallet,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCommentAltDots,
  faStar,
  faPencil,
  faCheckCircle,
  faTimesCircle,
  faEllipsisH,
  faEllipsisV,
  faHome,
  faChalkboardTeacher,
  faSync,
  faCommentLines,
  faBell,
  faCommentAltEdit,
  faFilePdf,
  faArrowAltCircleDown,
  faPaperPlane,
  faStickyNote,
  faBook,
  faInfoCircle,
  faMicrophone,
  faMicrophoneSlash,
  faPhoneHangup,
  faPhone,
  faArrowUp,
  faImage,
  faFileWord,
  faFileArchive,
  faFilePowerpoint,
  faGraduationCap,
  faBirthdayCake,
  faEnvelope,
  faVenusMars,
  faExclamationCircle,
  faSave,
  faCamera,
  faMapMarkerAlt,
  faLayerGroup,
  faCaretUp,
  faCaretDown,
  faCaretRight,
  faExclamationTriangle,
  faFileSpreadsheet,
  faHistory,
  faTimes as faTimeSolid,
  faReceipt,
  faAngleDown as faAngleDownSolid,
  faArrowDown,
  faCircle,
  faAsterisk,
  faChevronUp,
  faArrowLeft as faArrowLeftSolid,
  faBars,
  faCalendarAlt as faCalendarAltSoild,
  faInfinity,
  faArrowRight as faArrowRightSolid,
  faVenus,
  faMars,
  faBriefcase,
  faEye as faEyeSolid,
  faEyeSlash,
  faShare,
  faLightbulbOn,
  faHeartCircle,
  faLockAlt as faLockAltSolid,
  faVideo as faVideoSolid,
  faLongArrowUp,
  faVideoSlash as faVideoSlashSolid,
  faCreditCard,
  faScreencast,
  faMinus,
  faPlus as faPlusSoild,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faChevronUp,
  faCircle,
  faCommentAltDots,
  faArrowRight,
  faLongArrowDown,
  faAngleDown,
  faCommentAltEdit,
  faCog,
  faBell,
  faCommentLines,
  faSlidersV,
  faSearch,
  faSync,
  faNewspaper,
  faClipboard,
  faCalendarAlt,
  faCalendarAltSoild,
  faArrowLeft,
  faStar,
  faArrowToBottom,
  faFilePlus,
  faTimes,
  faCheckCircle,
  faHistory,
  faTimesCircle,
  faEllipsisH,
  faEllipsisV,
  faPencil,
  faTrashAlt,
  faClock,
  faAngleRight,
  faAngleLeft,
  faMapMarkerAlt,
  faHome,
  faChalkboardTeacher,
  faBookOpen,
  faEye,
  faFilePdf,
  faArrowAltCircleDown,
  faPaperPlane,
  faStickyNote,
  faBook,
  faInfoCircle,
  faPlus,
  faAngleUp,
  faPhoneHangup,
  faVideo,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faArrowUp,
  faImage,
  faFileWord,
  faFileArchive,
  faFilePowerpoint,
  faGraduationCap,
  faBirthdayCake,
  faEnvelope,
  faVenusMars,
  faExclamationCircle,
  faSave,
  faCamera,
  faExclamationCircleLight,
  faCheck,
  faLayerGroup,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFileSpreadsheet,
  faMoneyCheck,
  faTimeSolid,
  faCheckCircleLight,
  faReceipt,
  faAngleDownSolid,
  faArrowDown,
  faLockAlt,
  faAsterisk,
  faArrowLeftSolid,
  faBars,
  faInfinity,
  faArrowRightSolid,
  faVenus,
  faMars,
  faBriefcase,
  faEyeSolid,
  faShare,
  faLightbulbOn,
  faCaretRight,
  faHeartCircle,
  faUsdCircle,
  faUser,
  faLockAltSolid,
  faUserPlus,
  faShieldCheck,
  faVideoSolid,
  faLongArrowUp,
  faVideoSlashSolid,
  faWallet,
  faCreditCard,
  faScreencast,
  faEyeSlash,
  faMinus,
  faPlusSoild
);
