import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";

import { Trans, useTranslation } from "react-i18next";
import agent from "../../../app/api/agent";
import DateClickModal from "./../DateClickModal";
import "./index.scss";

const DateClickCalendar = ({ date, setDate, startDate, endDate, disable }) => {
  const { t } = useTranslation("toast");
  const language = t("toast:language");
  // LOCAL STATE DECLARATIONS
  const [modal, setModal] = useState("");
  const [lessons, setLessons] = useState(null);

  useEffect(() => {
    agent.Calendars.getActiveLesson().then((res) => setLessons(res.data));
  }, []);

  // FUNCTIONS DECLARATIONS
  const calendarHeader = {
    start: "",
    center: "",
    end: "title,prev,next",
  };

  const handleDateClick = (e) => {
    if (disable) return;
    // CHECK IS USER CLICK DATE OUT OF RANGE START - END DATE
    const today = moment();
    let startDateObj = moment(startDate);
    if (startDateObj < today) {
      startDateObj = today;
    }
    const endDateObj = moment(endDate);
    const clickedDateObj = moment(e.date);
    // 23/09/2021 @An báo lỗi gia sư chọn ngày học thử báo lỗi k có
    // ngày bắt đầu và ngày kết thúc. Thêm đk kiểm tra endDate,startDate
    // Bỏ trialDate => chỉ cần check nếu chọn bé hơn ngày hiện tại đẩy ra lỗi
    if (endDate && startDate) {
      if (startDateObj > clickedDateObj || endDateObj < clickedDateObj) {
        toast.error(
          <Trans
            i18nKey="toast:er_45"
            values={{
              v1: startDateObj.format("DD/MM/YYYY"),
              v2: endDateObj.format("DD/MM/YYYY"),
            }}
            components={{
              1: <span />,
            }}
          />
        );
        return;
      }
    }

    // let user pick current date

    const current = moment().hour(0);
    const clickedDay = moment(e.date).hour(0);

    if (current.diff(clickedDay, "hours") > 0) {
      toast.error(t("toast:er_46"));
      return;
    }

    setModal(e.dateStr);
  };

  function eventContent(evt) {
    // EXPTRACT PROPS
    const { event } = evt;
    let { start_time, end_time, recommended } = event._def.extendedProps;

    // Format time to hh:mm
    if (start_time) {
      start_time = start_time && start_time.slice(0, 5);
    }

    if (end_time) {
      end_time = end_time && end_time.slice(0, 5);
    }

    let dateOfMonth = event.start.getDate();

    return (
      <div
        style={{ background: "#D7EDFE" }}
        className={`h-100 center-box flex-column  ${
          recommended && "recommended"
        }`}
      >
        <h6 className="mb-0 text-bold text-center">{dateOfMonth}</h6>
        <div
          style={{ color: "#C5C6D1" }}
          className="flex-box justify-content-center flex-wrap text-small text-center mb-0"
        >
          <span>{start_time}</span>
          <span className="px-1"> - </span>
          <span>{end_time}</span>
        </div>
      </div>
    );
  }

  const events = date.map((d) => ({
    date: moment(d.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
    recommended: d.recommended,
    start_time: d.start_time,
    end_time: d.end_time,
  }));

  return (
    <div className="date-click-calendar">
      <FullCalendar
        headerToolbar={calendarHeader}
        timeZone="UTC"
        titleFormat={{ year: "numeric", month: "numeric" }}
        height={500}
        plugins={[interactionPlugin, dayGridPlugin]}
        selectable={true}
        locale={language}
        dateClick={(e) => {
          handleDateClick(e);
        }}
        events={events}
        eventContent={eventContent}
      />

      <Modal
        contentClassName="card-style"
        centered={true}
        isOpen={modal ? true : false}
      >
        <DateClickModal
          modal={modal}
          setModal={setModal}
          date={date}
          setDate={setDate}
          lessons={lessons}
        />
      </Modal>
    </div>
  );
};

DateClickCalendar.propTypes = {
  date: PropTypes.array,
  setDate: PropTypes.func,
};

export default DateClickCalendar;
