import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  room: null,
  roomInfo: null,
  isLoading: false,
};

export const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setRoom: (state, action) => {
      state.room = action.payload;
    },
  },
});

export const { setRoom } = videoSlice.actions;
