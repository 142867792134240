import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  UncontrolledDropdown
} from "reactstrap";
import TranslateDate from "./TranslateDate";

function DropdownDateOfWeek({ range, handleChangeRange }) {
  const { t } = useTranslation("date");
  // LOCAL STATE DECLARATIONS
  const [day_in_week, set_day_in_week] = useState(range.day_in_week || []);

  // SIDE EFFECTS
  useEffect(() => {
    handleChangeRange({ day_in_week });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day_in_week]);

  const init_day_in_week = [0, 1, 2, 3, 4, 5, 6];

  const listStr = day_in_week.sort();

  // FUNCTION DECLARATIONS
  function handleCheckbox(key) {
    if (day_in_week.includes(key)) {
      const update_day_in_week = day_in_week.filter((date) => date !== key);
      set_day_in_week([...update_day_in_week]);
    } else {
      set_day_in_week([...day_in_week, key]);
    }
  }

  return (
    <UncontrolledDropdown
      className="checkbox-dropdown flex-grow"
      style={{ width: "190px" }}
    >
      <DropdownToggle
        className={`border-0 w-100 text-truncate position-relative dropdown-btn bg-light text-left ${
          listStr.length > 0 ? "text-dark" : "text-grey"
        }`}
        style={{ height: "33.5px" }}
      >
        {/* LONG thêm sort cho ngày chọn */}
        {listStr.length > 0
          ? listStr.map((date) => t(date)).join(", ")
          : t("wait_indrop")}
        <div className="position-absolute" style={{ right: "5px", top: "30%" }}>
          <FontAwesomeIcon className="text-grey" icon={["fas", "angle-down"]} />
        </div>
      </DropdownToggle>

      <DropdownMenu className="w-100">
        {init_day_in_week.map((key, index) => (
          <DropdownItem
            className="flex-box align-items-center m-0 p-0 center-box"
            key={key}
            toggle={false}
            tag="div"
          >
            <Label
              className="flex-box w-100 mb-0 p-2"
              htmlFor={`date-${range.id}-${index}`}
            >
              <Input
                onChange={() => handleCheckbox(key)}
                value={key}
                id={`date-${range.id}-${index}`}
                type="checkbox"
                checked={day_in_week.includes(key) ? true : false}
                className="cursor-pointer me-2"
              />
              <TranslateDate
                datas={index}
                forName={`date-${range.id}-${index}`}
              />
            </Label>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

DropdownDateOfWeek.propTypes = {
  suggestRange: PropTypes.array,
  setSuggestRange: PropTypes.func,
};

export default DropdownDateOfWeek;
