import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

function StatusPaymentBox({ paymentStatus }) {
  const { t } = useTranslation("group-detail");
  return (
    <div
      className={`${
        paymentStatus ? "paid" : "not-paid"
      } border-radius-1 px-2 py-1 fw-bold`}
    >
      {paymentStatus ? t("done") : t("pending")}
    </div>
  );
}

export default StatusPaymentBox;
