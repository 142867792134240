import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { userTypeBaseRole } from "../../../app/utils/constant";
import HandleType from "../../dashboardTutorPage/BidRequestDetail/HandleType";
import Avatar from "../Avatar";
import LevelTranslation from "../LevelTranslation";
import SubjectImage from "../SubjectImage";
import SubjectTranslation from "../SubjectTranslation";
import "./index.scss";

CourseInfo.propTypes = { course: PropTypes.object, partner: PropTypes.object };

function CourseInfo({ course, partner }) {
  const { t } = useTranslation(["common"]);
  const { user } = useSelector(({ user }) => user);
  const userType = userTypeBaseRole[user.role];
  const {
    subject_level,
    offline_flag,
    number_lesson,
    subject_name,
    id,
    start_date,
  } = course;

  return (
    <Card className="card-style flex-box justify-content-between position-relative me-2 border">
      <div className="flex-box mb-3">
        <SubjectImage subject={subject_name} width="96px" />
        <div className="ms-2">
          <div className="text-small text-dark mb-2 py-1">
            <span>{id}</span>
          </div>
          <div className="h5 mb-2 text-bold2 font-weight-bold">
            <SubjectTranslation subject={subject_name} />
          </div>
          <div>
            <span className="text-grey me-2">{t("common:level")}: </span>
            <span className="text-bold1 font-weight-bold">
              <LevelTranslation level={subject_level} />
            </span>
          </div>
          <div className="mb-2">
            <span className="text-dark me-2 ">{t("common:is-offline")}</span>
            <span className="text-dark text-bold2 font-weight-bold">
              {offline_flag ? "Offline" : "Online"}
            </span>
          </div>
          <div>
            <span className="text-dark me-2">{t("common:start-course")}</span>
            <span className="text-dark text-bold2 font-weight-bold">
              {moment(start_date).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
      </div>
      <div className="text-center">
        <div className="mb-2">
          <Avatar avatar={partner?.avatar} width={64} name={partner?.name} />
        </div>
        <p className="text-center text-grey text-small mb-2">
          {userType === "student" ? t("common:teacher") : t("common:student")}
        </p>
        <div className="h6 text-bold2 mb-2 font-weight-bold">
          {partner?.name}
        </div>
        <div className="text-center text-small text-light text-bold2 w-100 hightlight-box-1 px-2 py-1">
          <span>{t("common:total")} </span>
          <span>
            {number_lesson}
            <HandleType />
          </span>
        </div>
      </div>
    </Card>
  );
}

export default CourseInfo;
