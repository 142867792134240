import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

export default function useFetch(
  api,
  setLoading,
  isPagination,
  payload,
  pageNo,
  filterFlag
) {
  // INIT LOCAL STATE
  const [data, setData] = useState();
  const { t } = useTranslation("toast");

  // SIDE EFFECTS
  useEffect(() => {
    fetchApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, filterFlag]);

  // FUNCTION DECLARATIONS
  async function fetchApi() {
    // Active loading
    const loadingId = uuidv4();
    setLoading((prevLoading) => [...prevLoading, loadingId]);

    await api(payload, pageNo)
      .then((res) => {
        let updatedData;

        if (isPagination) {
          const { count, next, previous, results } = res.data;
          updatedData = { count, next, previous, results };
        } else {
          updatedData = res.data;
        }

        setData(updatedData);
      })
      .catch(({ response }) => {
        toast.error(` ${t("toast:er_1")} ${response.status} !`);
      })
      .finally(() => {
        // Disable loading
        setLoading((prevLoading) => {
          const updateLoading = prevLoading.filter((ld) => ld !== loadingId);
          setLoading([...updateLoading]);
        });
      });
  }

  return data;
}
