import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";

const initialState = {
  status: "idle",
  coursesWithStatus3: null, // pending
  coursesWithStatus5: null, // inprogrress
  coursesWithStatus6: null, // done
};

export const fetchCourseAsync = createAsyncThunk(
  "course/fetchCourseAsync",
  async ({status}, thunkApi) => {
    try {
      // eslint-disable-next-line default-case
      switch (status) {
        case 3: return await agent.Courses.pending().then(res => res.data);
        case 5: return await agent.Courses.inprogress().then(res => res.data);
        case 6: return await agent.Courses.done().then(res => res.data);
      }
    } catch (error) {
      return thunkApi.rejectWithValue({error});
    }
  }
)

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCourseAsync.pending, (state, action) => {
      const {status} = action.meta.arg;
      state.status = "pendingFetchCourse-" + status;
    });

    builder.addCase(fetchCourseAsync.fulfilled, (state, action) => {
      const {status} = action.meta.arg;
      state.status = "idle";
      state["coursesWithStatus" + status] = action.payload;
    });

    builder.addCase(fetchCourseAsync.rejected, state => {
      state.status = "idle";
    });
  }
});