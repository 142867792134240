import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import useWindowSize from "../customHooks/useWindowSize";

GobackNew.propTypes = {
  clas: PropTypes.string,
  functions: PropTypes.func,
  to: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.object,
};

export default function GobackNew({
  clas,
  functions,
  to,
  text,
  children,
  ...passProps
}) {
  const history = useHistory();
  const { t } = useTranslation("common");
  const [width] = useWindowSize();
  let Comp = "div";
  const props = { ...passProps };

  if (functions) {
    props.onClick = functions;
  } else {
    if (to) {
      Comp = Link;
      props.to = to;
    } else {
      props.onClick = history.goBack;
    }
  }

  return (
    <Comp
      className={` ${
        clas
          ? clas
          : "center-box text-bold2 py-2 px-3 border-radius-2 go-back-new my-2"
      } `}
      {...props}
    >
      {width > 768 ? (
        <>
          <FontAwesomeIcon className="me-2" icon={["fal", "arrow-left"]} />
          <span>{text ? text : t("back-btn")}</span>
        </>
      ) : (
        <FontAwesomeIcon
          icon={["fal", "angle-left"]}
          size="2x"
          className="text-hightlight1"
        />
      )}
      {children}
    </Comp>
  );
}
