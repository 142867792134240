import React from "react";
import { useTranslation } from "react-i18next";

const StepRequest = ({ step }) => {
  const { t } = useTranslation("request-step");
  return (
    <div className="step-request d-flex flex-wrap px-3 py-0">
      <div className="hidden-text flex-box align-items-center my-3">
        <label
          className={`step rounded-circle center-box mb-0 me-2 font-weight-bold text-grey 
          ${step >= 1 && "active-step"}         
          `}
        >
          1
        </label>
        <span
          className={`text-center fist text-nowrap
          ${step >= 1 ? "text-dark text-bold2" : "text-grey"}          
          `}
        >
          {t("step-1")}
        </span>
      </div>
      <div
        className={`${
          step > 1 ? "text-hightlight" : "text-grey"
        } center-box flex-grow text-center text-nowrap`}
      >
        -------
      </div>
      <div className="hidden-text flex-box align-items-center my-3">
        <label
          className={`step rounded-circle center-box mb-0 me-2 font-weight-bold text-grey         
          ${step >= 2 && "active-step"}
         
          `}
        >
          2
        </label>
        <span
          className={`text-center text-nowrap        
          ${step >= 2 ? "text-dark text-bold2" : "text-grey"}         
          `}
        >
          {t("step-2")}
        </span>
      </div>
      <div
        className={`${
          step > 2 ? "text-hightlight" : "text-grey"
        } center-box flex-grow text-center text-nowrap`}
      >
        -------
      </div>
      <div className="hidden-text flex-box align-items-center my-3">
        <label
          className={`step rounded-circle center-box mb-0 me-2 font-weight-bold text-grey         
          ${step >= 3 && "active-step"}         
          `}
        >
          3
        </label>
        <span
          className={`text-center text-nowrap        
          ${step >= 3 ? "text-dark text-bold2" : "text-grey"}       
          `}
        >
          {t("step-3")}
        </span>
      </div>
      <div
        className={`${
          step > 3 ? "text-hightlight" : "text-grey"
        } center-box flex-grow text-center text-nowrap`}
      >
        -------
      </div>
      <div className="hidden-text flex-box align-items-center my-3">
        <label
          className={`step rounded-circle center-box mb-0 me-2 font-weight-bold text-grey         
          ${step >= 4 && "active-step"}         
          `}
        >
          4
        </label>
        <span
          className={`text-center text-nowrap       
          ${step >= 4 ? "text-dark text-bold2" : "text-grey"}         
          `}
        >
          {t("step-4")}
        </span>
      </div>
      <div
        className={`${
          step > 4 ? "text-hightlight" : "text-grey"
        } center-box flex-grow text-center text-nowrap`}
      >
        -------
      </div>
      <div className="hidden-text flex-box align-items-center justify-content-end my-3">
        <label
          className={`step rounded-circle center-box mb-0 me-2 font-weight-bold text-grey         
          ${step === 5 && "active-step"}
          `}
        >
          5
        </label>
        <span
          className={`text-center ps-2 pe-2 last        
          ${step === 5 ? "text-dark text-bold2" : "text-grey"}
          `}
        >
          {t("step-5")}
        </span>
      </div>
    </div>
  );
};
export default StepRequest;
