import axios from "axios";
import { toast } from "react-toastify";
import { history } from "../..";
import { store } from "../store/configuringStore";
import { logout, refreshTokenAsync } from "../store/userSlice";
import { PAGE_SIZE } from "../utils/constant";

axios.defaults.baseURL =
  process.env.REACT_APP_BASE_URL || "https://api.akadon.edu.vn/api/";

axios.interceptors.request.use((config) => {
  const { customToken } = config.params || {};

  if (customToken) delete config.params.customToken;

  const token = customToken || store.getState().user.accessToken;

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const languageLocal = localStorage.getItem("language");
    const { status, config } = err.response || {};

    // HANDLE REFRESH TOKEN
    if (status > 400 && config.url?.includes("auth/refresh_token/")) {
      store.dispatch(logout());
      localStorage.clear();
      history.push("/user/login");
    }

    if (
      status === 401 &&
      !config.url?.includes("auth/login/") &&
      !config.url?.includes("auth/logout/") &&
      !config.url?.includes("auth/refresh_token/") &&
      !store.getState().user.isRefreshToken
    ) {
      store.dispatch(refreshTokenAsync(store.getState().user.accessToken));
      window.alert(
        languageLocal === "vi"
          ? "Phiên đăng nhập của bạn đã hết hạn. Vui lòng thử lại hoặc tải lại trang!"
          : "Your login session has expired. Please try again or reload the page"
      );
    }
    if (status === 500) {
      toast.error(
        languageLocal === "vi"
          ? "Hệ thống đang bảo trì: " + status
          : "System update: " + status
      );
    }

    return Promise.reject(err);
  }
);
// WITHOUT Authorization
var instance = axios.create();
delete instance.defaults.headers.common["Authorization"];
const requestNoAuthor = {
  get: (url, params = {}) => instance.get(url, { params }),
  post: (url, payload, params = {}) => instance.post(url, payload, { params }),
  put: (url, payload, params = {}) => instance.put(url, payload, { params }),
  delete: (url, params = {}) => instance.delete(url, { params }),
};

const NoAuther = {
  getRecommendTutor: () => requestNoAuthor.get("users/outstanding_tutor/"),
  faqQuestion: (payload) =>
    requestNoAuthor.post("questions/create_question/", payload),
  refreshToken: (token) =>
    requestNoAuthor.post("auth/refresh_token/", { token }),
  Tutor: {
    profileReviews: (payload, page) =>
      requestNoAuthor.post(`users/profile/reviews/?page=${page}`, payload),
    publicProfile: (chat_unique) =>
      requestNoAuthor.get(`users/profile/${chat_unique}/`),
  },
};

const request = {
  get: (url, params = {}) => axios.get(url, { params }),
  post: (url, payload, params = {}) => axios.post(url, payload, { params }),
  put: (url, payload, params = {}) => axios.put(url, payload, { params }),
  delete: (url, params = {}, payload) =>
    axios.delete(url, { data: payload }, { params }),
};

const User = {
  logout: () => request.post("auth/logout/", {}),
  // LOGIN API
  login: (payload) => request.post("auth/login/", payload),
  facebookLogin: (payload) => request.post("users/facebook/login/", payload),
  zaloLogin: (payload) => request.post("users/zalo/login/", payload),
  // REGISTER API
  register: (payload) => request.post("users/registration/", payload),
  facebookRegister: (payload) =>
    request.post("users/facebook/registration/", payload),
  zaloRegister: (payload) => request.post("users/zalo/registration/", payload),
  zaloRegisterInfo: (payload) => request.post("users/zalo/get_info/", payload),
  // PASSWORD AND VERIFY
  changePassword: (code, new_password) =>
    request.post("auth/change_password/", { code, new_password }),
  forgotPassword: (email, phone) =>
    request.post("auth/forgot_password/", { email, phone }),
  sendVerifyEmail: (email, role) =>
    request.post("users/send_regis_verify_code/", { email, role }),
  confirmVerifyEmailCode: (payload) =>
    request.post("users/confirm_regis_verify_code/", payload),
  sendVerifySmsCode: (phone_number) =>
    request.post("users/sms/send_confirm_code/", { phone_number }),
  confirmVerifySmsCode: (code, phone_number) =>
    request.post("users/sms/verify_confirm_code/", { code, phone_number }),
  // CREATE+EDEIT REQUEST FORM
  createRequestForm: (payload) =>
    request.post("applications/create_open_contract/", payload),
  getRequestDetail: (requestId) =>
    request.get(`applications/contract/${requestId}/`),
  // USER INFO
  info: () => request.get("users/"),
  setting: (payload) => request.post("users/settings/", payload),
  update: (payload) => request.put("users/", payload),

  publicProfile: (chat_unique) => request.get(`users/profile/${chat_unique}/`),
  UserProfile: () => request.get("users/profile/"),
  updateTutorInfo: (payload) => request.put("users/profile/update/", payload),
  getSchoolList: () => request.get("users/school_list/"),
  // GET INFO ABOUT ACCOUNT TUTOR
  getLimit: () => request.get("applications/tutor/get_limit/"),
  upgradeAccountInfo: (payload) =>
    request.post("users/upgrade_account/upload_profile/", payload),
};

const Password = {
  verify: (password) => request.post("users/password_security/", { password }),
  getSmsCode: (payload) =>
    request.post("users/sms/send_confirm_code/", payload),
};

const Profile = {
  courseOverview: () => request.get("users/profile_info/recent_subjects/"),
  studentReviewList: (page = 1, star) =>
    request.get("applications/tutor/review_list/", {
      page,
      star,
      page_size: PAGE_SIZE,
    }),
  settingData: () => request.get("users/settings/"),
  schoolList: () => request.get("users/school_list/"),
  updateAvatar: (formData) => request.post("users/update_avatar/", formData),
  addCertificate: (payload) =>
    request.post("users/profile/judicial_record/create/", payload),
  updateCertificate: (payload) =>
    request.put("users/profile/judicial_record/update/", payload),
  review: (user_code, page = 1) =>
    request.post("users/profile/reviews/", { user_code }, { page }),
  deleteJudicialRecord: (jr_id) =>
    request.delete("users/profile/judicial_record/update/", {}, { jr_id }),
  updateInfo: (payload) => request.put("users/profile/update/", payload),
  studentContractLists: (page = 1) =>
    request.get("applications/student/paid_settings/contract_list/", { page }),
  setAutoPaid: (contract_id, is_auto_paid) =>
    request.post("applications/student/paid_settings/update/", {
      contract_id,
      is_auto_paid,
    }),
};

const Tutor = {
  list: (payload, page) =>
    request.post("users/landing_page_tutor_list/", payload, { page }),
};

// Contracts with status:
// 1. status === 1 => yêu cầu đang mở
// 2. status === 8 => yêu cầu đang chờ học thử
// 3. status === 4 => yêu cầu đã đóng
const Contracts = {
  // CREATE+EDEIT REQUEST FORM
  createRequestForm: (payload) =>
    request.post("applications/create_open_contract/", payload),
  getRequestDetail: (requestId) =>
    request.get(`applications/contract/${requestId}/`),
  getUpcomingLessons: () =>
    request.get("applications/contract/upcoming_lesson/"),
  getContractSummaryV2: () => request.get("applications/total_contract/v2/"),
  getBidDetail: (bidId) => request.get(`applications/bid_contract/${bidId}/`),
  student: {
    // payload shape: {status: 1 | 4 | 8}
    getBaseStatus: (payload, pageNo = 1) =>
      request.post("/applications/student/open_contracts/", payload, {
        page: pageNo,
        page_size: PAGE_SIZE,
      }),
  },
  tutor: {
    // payload shape: {status: 1 | 4 | 8}
    getBaseStatus: (payload, pageNo) =>
      request.post("applications/tutor/contract_bided/", payload, {
        page: pageNo,
        page_size: PAGE_SIZE,
      }),
    getOpen: ({ pageNo, ...payload }) =>
      request.post("applications/tutor/open_contracts/", payload, {
        page: pageNo || 1,
      }),
  },
  bidContract: (payload) => request.post("applications/bid_contract/", payload),
};

const lessons = {
  getLessonDetail: (lessonId) =>
    request.get(`applications/lesson/detail/${lessonId}/`),
  lessonClose: (lesson_id) =>
    request.post("applications/lesson/close/", { lesson_id }),
  // HOMEWORK DOCUMENT
  getListHomework: (payload) =>
    request.post("applications/homework/list/", payload),
  uploadHomework: (payload) =>
    request.post("applications/homework/upload/", payload),
  uploadDoc: (payload) =>
    request.post("applications/study_docs/upload/", payload),
  deleteFile: (payload) => request.post("applications/files/delete/", payload),
  lessonFeedback: (payload) =>
    request.post("applications/lesson/reviews/", payload),
  review: (payload) =>
    request.post("applications/lesson/review/create/", payload),
  note: (lesson_id, note) =>
    request.post("applications/lesson/note/create/", { lesson_id, note }),
  noteList: (lesson_id) =>
    request.post("applications/lesson/note/list/", { lesson_id }),
  additionLesson: (lesson_request_id) =>
    request.post("applications/lesson/get_request_more/", {
      lesson_request_id,
    }),
  acceptOrDenyEditLessonRequest: (lesson_id, decide) =>
    request.post("applications/courses/change_lesson_decide/", {
      lesson_id,
      decide,
    }),
  closeTrialLesson: (lesson_id) =>
    request.post("applications/trial_lesson/force_cancel/", { lesson_id }),
  downloadHomework: (file_id) =>
    request.post(
      "applications/homework/download/",
      { file_id },
      { responseType: "blob" }
    ),
};

const QRCode = {
  lesson: (customToken) =>
    request.get("applications/qr_code/lesson/get_info/", { customToken }),
  startLesson: (customToken) =>
    request.get("applications/qr_code/lesson/start_lesson/", { customToken }),
  closeLesson: (customToken) =>
    request.get("applications/qr_code/lesson/close_lesson/", { customToken }),
  reviewLesson: (payload, customToken) =>
    request.post("applications/qr_code/lesson/review_lesson/", payload, {
      customToken,
    }),
};

const Courses = {
  inprogress: () => request.get("applications/student/courses/?status=5"),
  pending: () => request.get("applications/student/courses/?status=3"),
  done: () => request.get("applications/student/courses/?status=6"),
  getCourseDetail: (courseid) =>
    request.get(`applications/student/courses/${courseid}/`),
  addLessons: (payload) =>
    request.post("applications/lesson/request_more/", payload),
  acceptOrDenyAddLessonsRequest: (payload) =>
    request.post("applications/lesson/request_more/decide/", payload),
  reviewCourse: (payload) =>
    request.post("applications/contract/review/create/", payload),
  tutor: {
    submitSchedule: (payload) =>
      request.post("applications/tutor/start_contract_trial/", payload),
    getRecommendTutor: () => request.get("users/outstanding_tutor/"),
  },
  student: {
    rejectPendingCourse: (payload) =>
      request.post("applications/student/accept_reject_timeline/", payload),
  },
  detail: (contractId) => request.get(`applications/contract/${contractId}/`),
};

const Calendars = {
  getAllSettingTimes: () => request.get("event/all/"),
  studentGetTutorSettingTimes: (tutorId) =>
    request.get(`event/all/${tutorId}/`),
  updateSchedule: (payload) =>
    request.post("applications/courses/change_lesson_schedule/", payload),
  updateTrialLesson: (payload) =>
    request.post("applications/trial_lesson/change_date/", payload),
  getActiveLesson: () =>
    request.get("applications/student/get_active_lessons/"),
  setFreeTime: (payload) => request.post("event/create/", payload),
  deleteFreeTime: (id) => request.delete(`api/event/detail/${id}/`),
  updateFreeTime: (payload, id) =>
    request.delete(`api/event/detail/${id}/`, payload),
};

const ClassRoom = {
  createClassRoom: (payload) => request.post("classrooms/", payload),
  searchStudent: (params) => request.get(`classrooms/student/list/?${params}`),
  listGroup: ({ status, page = 1, subject_name, subject_level }) =>
    request.get(
      `classrooms/list/`,
      { status },
      { page },
      { subject_name },
      { subject_level }
    ),
  getGroupDetail: (id) => request.get(`classrooms/detail/${id}/`),
  getGroupLessonDetail: (lesson_id) =>
    request.get("classrooms/lesson/", { lesson_id }),
};

const Requests = {
  getBidList: (payload, page = 2) =>
    request.post("applications/bid_contracts/", payload, { page }),
  pushRequest: (payload) =>
    request.post("applications/student/push_contract_top/", payload),
  submitTrialDate: (payload) =>
    request.post("applications/student/select_trial_date/", payload),
  rejectBidContract: (payload) =>
    request.post("applications/student/reject_bid_contract/", payload),
  cancelRequest: (payload) =>
    request.post("applications/cancel_contract/", payload),
  acceptOrRejectTrialSession: (payload) =>
    request.post("applications/student/accept_reject_course/", payload),
  getRequestDetail: (requestId) =>
    request.get(`applications/contract/${requestId}/`, { requestId }),
  pushHistory: (contract_id) =>
    request.post("applications/student/push_contract_history/", {
      contract_id,
    }),
  closedRequests: (status, page = 1) =>
    request.post("applications/student/open_contracts/", { status }, { page }),
  createNoAuthRequest: (payload) =>
    request.post("applications/landing_page/contract/create/", payload),
};

const Chat = {
  history: () => request.get("chat/history/"),
  detail: (room) => request.get("chat/detail/", { room }),
  getChatToken: (user_receive_id) =>
    request.post("chat/join/", { user_receive_id }),
  startVideoCall: (payload) => request.post("video_call/start/", payload),
  postVideoCallStartTime: (payload) =>
    request.post("applications/lesson/start_count_time/", payload),
  systemReport: (chat_message) =>
    request.post("users/hbi/leak_phone_email_info/", { chat_message }),
  report: (payload) => request.post("chat/report/", payload),
};

const Video = {
  start: (lesson_id) => request.post("video_call/start/", { lesson_id }),
  startLessonCall: (real_start_time, lesson_id) =>
    request.post("applications/lesson/start_count_time/", {
      real_start_time, // TIME FORMAT: "DD/MM/YYYY-HH:mm:ss
      lesson_id,
    }),
};

const Notify = {
  getList: (pageNo = 1) =>
    request.get("users/notifications/", { page: pageNo }),
  unReadCount: () => request.get("users/notification/count/"),
  markRead: (noti_id) => {
    request.get("users/notification/", { noti_id });
  },
  markAllRead: () => request.post("users/notification/read_all/", {}),
};

const Payment = {
  history: () => request.get("payments/payment_history/"),
  lessonList: (contract_id) =>
    request.get("payments/lesson_payment_list/", { contract_id }),
  detail: (lesson_id) =>
    request.post("payments/payment_detail/", { lesson_id }),
  create: (payload) => request.post("payments/create_payment/", payload),
  update: (payload) => request.post("payments/update_payment/", payload),
  checkoutWithBalance: (vpc_Amount, priority) =>
    request.post("payments/upgrade_acc_sys_balance/", { vpc_Amount, priority }),
  checkoutWithOnePay: (payload) =>
    request.post("users/upgrade_account/create_payment/", payload),
  getBankList: () => request.get("payments/list_account/"),
  getPaymentHistory: () => request.get("payments/payment_history/"),
  getPaymentLessonsInfo: (pageNo) =>
    request.get(`payments/payment_lessons_info/?page=${pageNo}`),
  addBank: (payload) => request.post("payments/add_bank/", payload),
  otp: (phone_number) =>
    request.post("payments/send_confirm_bank/", { phone_number }),
  setDefaultBank: (bank_account_id) =>
    request.post("payments/set_default_bank/", { bank_account_id }),
  payout: (payload) => request.post("payments/payout_onepay/", payload),
  getVnpayUrl: (payload) => request.post("payments/vnpay/create_url/", payload),
};

const SystemMessages = {
  readAll: () => request.post("system_messages/all/read/"),
  getSystemMessages: (pageNo) =>
    request.post(`system_messages/all/?page=${pageNo}`),
};

const Qanda = {
  create: (payload) => request.post("users/qanda/quick_req/", payload),
  register: (payload) => request.post("users/qanda/registration/", payload),
  updateUser: (payload) =>
    request.post("users/qanda/update_user_info/", payload),
};

const Vouchers = {
  list: () => request.get("applications/vouchers/"),
};

const agent = {
  User,
  Profile,
  Contracts,
  Courses,
  Requests,
  Calendars,
  Chat,
  Notify,
  lessons,
  Payment,
  NoAuther,
  SystemMessages,
  QRCode,
  Tutor,
  Qanda,
  Password,
  Video,
  Vouchers,
  ClassRoom,
};

export default agent;
