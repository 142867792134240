import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../api/agent"

const initialState = {
  sentRequests: null,
  trialLessonRequests: null,
  closedRequests: null,
  status: false,
};

export const fetchRequestsBaseStatusAsync = createAsyncThunk(
  "teachRequest/fetchSentRequestsAsync",
  async ({ status, pageNo }, thunkApi) => {
    try {
      
      const data = await agent.Contracts.tutor
        .getBaseStatus({ status }, pageNo)
        .then((res) => res.data);
      if (pageNo > 1) {
        const { teachRequest } = thunkApi.getState();
        switch (status) {
          case 1:
            return {
              ...teachRequest.sentRequests,
              ...data,
              results: [...teachRequest.sentRequests?.results, ...data.results],
            };
          case 4:
            return {
              ...teachRequest.closedRequests,
              ...data,
              results: [...teachRequest.closedRequests?.results, ...data.results],
            };
          case 8:
            return {
              ...teachRequest.trialLessonRequests,
              ...data,
              results: [...teachRequest.trialLessonRequests?.results, ...data.results],
            };
          default:
            break;
        }
      } else {
        return data;
      }
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const teachRequestSlice = createSlice({
  name: "teachRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRequestsBaseStatusAsync.pending, (state, action) => {
      const { status } = action.meta.arg;
      state.status = "loadingRequestsWithStatus-" + status;
    });
    builder.addCase(fetchRequestsBaseStatusAsync.fulfilled, (state, action) => {
      const { status } = action.meta.arg;
      state.status = "idle";
      switch (status) {
        case 1:
          state.sentRequests = action.payload;
          break;
        case 4:
          state.closedRequests = action.payload;
          break;
        case 8:
          state.trialLessonRequests = action.payload;
          break;
        default:
          break;
      }
    });
    builder.addCase(fetchRequestsBaseStatusAsync.rejected, (state) => {
      state.status = "idle";
    });
  },
});
